// For first page load and search by tags only (other unsplash code in _new_unsplash)

// Search photos when search keyword buttons are pressed
function searchPhotos(keyword) {
  $.ajax({
    type: 'get',
    url: '/users/unsplash',
    data: {
      query: keyword,
    },
  });
}

$(document).on('turbo:load', () => {
  // When on Unsplash page only
  if (document.querySelector('.unsplash-results')) {
    // Display unsplash photos for first load
    searchPhotos('study');
  }

  // Control up/down icon for Unsplash accordion
  $('.collapse')
    .on('shown.bs.collapse', function () {
      $(this)
        .parent()
        .find('.fe-arrow-down')
        .removeClass('fe-arrow-down')
        .addClass('fe-arrow-up');
    })
    .on('hidden.bs.collapse', function () {
      $(this)
        .parent()
        .find('.fe-arrow-up')
        .removeClass('fe-arrow-up')
        .addClass('fe-arrow-down');
    });
});

/**
 * unsplash pagination
 */

// settings
const UNSPLASH = {
  clientId: 'DF7nAtq94a3cxAlwVEiAucFNd1LzWmvelvLjrJOtyKA',
  endpoints: {
    top: 'https://api.unsplash.com/photos/',
    search: 'https://api.unsplash.com/search/photos/',
  },
  params: {
    orderBy: 'popular',
    perPage: '10',
  },
};

function unsplashPagination(ACTION) {
  // map action to modal id scope
  const SCOPE = {
    'new': '#NewEventModal',
    'edit': '#EditEventModal',
  }[ACTION];

  // list of variable event handlers
  const SELECTORS = {
    results: `${SCOPE} [data-js-results]`,
    textInput: `${SCOPE} [data-js-photosearch]`,
    textSearchForm: `${SCOPE} [data-js-text-search-form]`,
    loadMoreWrapper: `${SCOPE} [data-js-load-more-wrapper]`,
    clickImage: `${SCOPE} [data-js-click-image]`,
    clickLoadMore: `${SCOPE} [data-js-click-load-more]`,
    clickClearPage: `${SCOPE} [data-js-click-clear-page]`,
    clickSearchTag: `${SCOPE} [data-js-click-search-tag]`,
    clickCloseModal: `${SCOPE} [data-js-close-modal]`,
    highlightImage: `${SCOPE} [data-js-highlight-image]`,
  };

  // state handlers
  const STATE = {
    searchText: '',
    resultsPageCount: 0,
  };

  function setResultsPageCount(newVal) {
    STATE.resultsPageCount = newVal;
  }

  function setSearchText(newVal) {
    STATE.searchText = newVal;
  }

  // supporting functions

  function formatPhotoCredit(user) {
    if (!user.last_name) {
      return user.first_name;
    }
    if (!user.first_name) {
      return user.last_name;
    }
    return `${user.first_name} ${user.last_name}`;
  }

  function isNewSearch(query) {
    return query !== STATE.searchText;
  }

  function resetPageForNewSearch(query) {
    if (isNewSearch()) {
      $(SELECTORS.textInput).val(query);
      $(SELECTORS.loadMoreWrapper).css('visibility', 'visible');
    }
    $(SELECTORS.results).html('');
    setSearchText(query);
    setResultsPageCount(0);
  }

  function resetState() {
    $(SELECTORS.textInput).val('');
    $(SELECTORS.results).html('');
    $('.clear-icon').css('opacity', '0');
    setResultsPageCount(0);
  }

  function updateDomNoResults(message) {
    $(SELECTORS.results).append(`<p class="notfound">${message}</p>`);
    $(SELECTORS.loadMoreWrapper).css('visibility', 'hidden');
  }

  function updateDomWithResults(data) {
    $(SELECTORS.loadMoreWrapper).remove();
    let resultsHtml = '';
    data.forEach((photo) => {
      const formattedPhotoCredit = formatPhotoCredit(photo.user);
      resultsHtml += `
        <div
          class="unsplash-image-wrapper"
          data-js-click-image
          data-js-highlight-image
          data-js-image-id="${photo.id}"
          data-js-image-href="${photo.urls.small}"
          data-js-image-credit="${formattedPhotoCredit}"
          style="background-image: url('${photo.urls.thumb}');"
          role="img"
          alt="${formattedPhotoCredit}"
        >
          <a class="photocredit photocredit-small" href="${photo.user.links.html}" target="_blank">${formattedPhotoCredit}</a>
        </div>
      `;
    });

    const loadMoreButtonHtml = `
      <div class="load-more-wrapper" data-js-load-more-wrapper>
        <button class="load-more-button btn-secondary" type="button" data-js-click-load-more>See more images</button>
      </div>
    `;

    $(SELECTORS.results).append(resultsHtml += loadMoreButtonHtml);
  }

  function updateDom(data, page) {
    if (data.length === 0 || (data.total && data.total === 0)) {
      updateDomNoResults(`Sorry, we couldn't find any images for <b>"${searchval}"</b>`);
      setResultsPageCount(0);
    } else if (data.total_pages === page) {
      updateDomNoResults('End of results');
    } else {
      updateDomWithResults(data.results || data);
    }
  }

  function getPhotos(query, page) {
    const defaultSearch = 'Top photos';
    const useDefaultSearch = query === '';
    const resultsTitle = useDefaultSearch ? defaultSearch : SELECTORS.Results;
    const url = `${
      useDefaultSearch ? UNSPLASH.endpoints.top : UNSPLASH.endpoints.search
    }?client_id=${UNSPLASH.clientId}&page=${page}&order_by=${
      UNSPLASH.params.orderBy
    }&per_page=${UNSPLASH.params.perPage}&query=${query}&content_filter=high`;
    $('#results-label').html(`<p class="nav-text">${resultsTitle}</p>`);

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response);
      })
      .then((data) => {
        updateDom(data, page);
        setResultsPageCount(STATE.resultsPageCount + 1);
      })
      .catch((error) => console.log(error));
  }

  // event handlers

  function handleFormSubmit(e) {
    e.preventDefault();
    const query = $(SELECTORS.textInput).val();
    resetPageForNewSearch(query);
    getPhotos(query, STATE.resultsPageCount);
  }

  function handleLoadMoreImagesButtonClick() {
    setResultsPageCount(STATE.resultsPageCount + 1);
    const query = $(SELECTORS.textInput).val();
    getPhotos(query, STATE.resultsPageCount);
  }

  function highlightImage(image) {
    const allImages =  document.querySelectorAll(SELECTORS.highlightImage);
    allImages.forEach((image) => {
      image.classList.remove('highlighted-image');
    });
    image.classList.add('highlighted-image');
  }

  function handleImageClick() {
    const imgId = this.getAttribute('data-js-image-id');
    const imgHref = this.getAttribute('data-js-image-href');
    const imgCredit = this.getAttribute('data-js-image-credit');

    $(`${SCOPE} #selected-image-id`).attr('value', imgId);
    $(`${SCOPE} #display-image`).attr('src', imgHref);
    $(`${SCOPE} #display-image-credit`).attr('href', imgHref).text(imgCredit);

    highlightImage(this);
  }

  function handleSearchTagClick() {
    const query = $(this).val();
    resetPageForNewSearch(query);
    getPhotos(query, 1);
  }

  function handleCloseModalClick(e) {
    resetState();
    if (e.target.id === 'EditEventModal') {
      unbindHandlers('#EditEventModal');
    }
  }

  function unbindHandlers(id) {
    $(document).off('hidden.bs.modal', SCOPE);
    $(`${id} [data-js-text-search-form]`).off('submit');
    $(document).off('click', `${id} [data-js-click-search-tag]`);
    $(document).off('click', `${id} [data-js-click-load-more]`);
    $(document).off('click', `${id} [data-js-click-image]`);
    $(document).off('click', `${id} [data-js-highlight-image]`);
  }

  function initModal() {
    // for new event modal, fetch photos on model open
    if (ACTION === 'new') {
      // unbind if already bound to new event modal
      unbindHandlers('#NewEventModal');
      $(document).off('click', '#newevent[data-js-open-modal]');
      $(document).off('click', '[data-navlink]');
      // bind for simple view
      $(document).on('click', '#newevent[data-js-open-modal]', () => getPhotos('', 1));
      // bind for full-cal view
      $(document).on('click', '[data-navlink]', () => getPhotos('', 1));
    }

    // for edit event modal, fetch photos each time modal is opened
    if (ACTION === 'edit') {
      getPhotos('', 1);
    }

    // attach event listeners
    $(document).on('hidden.bs.modal', SCOPE, handleCloseModalClick);
    $(`${SCOPE} [data-js-text-search-form]`).on('submit', handleFormSubmit);
    $(document).on('click', `${SCOPE} [data-js-click-search-tag]`, handleSearchTagClick);
    $(document).on('click', `${SCOPE} [data-js-click-load-more]`, handleLoadMoreImagesButtonClick);
    $(document).on('click', `${SCOPE} [data-js-click-image]`, handleImageClick);
  }

  // initialize modal on script load
  initModal();
}

export { searchPhotos, unsplashPagination };
