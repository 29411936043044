import Swal from "sweetalert2";

// Sweet alert button with AVC default button styling
const swalWithAVCButtons = Swal.mixin({
  customClass: {
    actions: "swal-vertical",
    confirmButton: "btn btn-primary btn-swal mx-2",
    denyButton: "btn btn-primary btn-swal mx-2",
    cancelButton: "btn btn-secondary btn-swal mx-2"
  },
  showClass: {
    popup: 'swal-title' // Removes bounce animation and adds style for title font.
  },
  buttonsStyling: false,
  focusCancel: true
});

export {swalWithAVCButtons};
