// This file is automatically compiled by Webpack, along with any other files present in this directory.
// You're encouraged to place your actual application logic in a relevant structure within app/javascript.

require("@rails/ujs").start(); // needed only for unsplash
require("@hotwired/turbo-rails");
require("@rails/activestorage").start();
require("channels");

// ----------------------------------------------------
// ABOVE IS RAILS DEFAULT CONFIGURATION
// ----------------------------------------------------

// True = Turbo Drive enabled globally
import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = true;

// External imports
import "bootstrap";
import jstz from "jstz";

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

var moment = require("moment");
global.moment = moment;
window.moment = moment;

global.I18n = I18n;
window.I18n = I18n;

// Load env variables from .env
require('dotenv').config();

// Internal imports
require("plugins/cookies");
require("plugins/email");
require("plugins/recurring_select.js.erb");
import "controllers"; // Import Stimulus controllers
import { calendarSwitch } from "../plugins/calendarswitch";
import { gtag } from "../plugins/analytics";
import { voiceItems } from "../plugins/voiceitems";
import { initFlatpickr } from "../plugins/flatpickr";
import { initSweetDelete } from "../plugins/sweetdelete";
import { initSweetEdit } from "../plugins/sweetedit";
import {
  inputHelpers,
  registrationFix,
  signInFix,
  populateDates,
} from "../plugins/formhelpers";
import { I18n } from "i18n-js/index.js.erb";
import { photoPreview } from "../plugins/photopreview";
import { stickyNavbar } from "../plugins/stickynavbar";
import { languageToggle } from "../plugins/languageToggle";
import { searchPhotos, unsplashPagination } from "../plugins/unsplash";
import { setCookie } from "../plugins/timezone";
import { startShepherd } from "../shepherd/shepherd-util";
import { weather } from "../plugins/openweather";
import { voiceContent } from "../plugins/voicecontent";
import { confettiAndSound } from "../plugins/confettisound";
import { highlightBorder } from "../plugins/highlightborder";

document.addEventListener("turbo:load", () => {
  // Runs on initial page load
  calendarSwitch();
  gtag();
  inputHelpers();
  initFlatpickr();
  initSweetDelete(".sweet-delete");
  photoPreview();
  registrationFix();
  signInFix();
  startShepherd();
  stickyNavbar();
  languageToggle();
  voiceItems();
  voiceContent();
  weather();
  unsplashPagination("new");
  populateDates();
  confettiAndSound();
  highlightBorder();

  window.searchPhotos = searchPhotos; // Available to form
  global.searchPhotos = searchPhotos; // Available to modal controller
  window.initSweetDelete = initSweetDelete; // Available to parent portal after ajax refresh

  // Get timezone from browser cookie
  const timezone = jstz.determine();
  setCookie("timezone", timezone.name());

  // removes Turbo progress bar if it lingers after new event added
  setTimeout(() => removeProgressBar(), 1000);
});

document.addEventListener("turbo:frame-load", () => {
  // Runs on edit modal open; edit modal contained within Turbo Frame
  initEventForms();
  unsplashPagination("edit");
});

document.addEventListener("turbo:before-stream-render", () => {
  // Runs on event create, update, delete, etc (updated by Turbo Stream)
  // Delay necessary as no turbo-after-stream-render event available
  setTimeout(() => initEventForms(), 1000);
});

const initEventForms = () => {
  initFlatpickr();
  initSweetDelete(".sweet-delete");
  initSweetEdit(".sweet-edit"); // edit only
  inputHelpers();
  photoPreview();
  searchPhotos();
  voiceContent();
};

const removeProgressBar = () => {
  const progressBar = document.querySelector(".turbo-progress-bar");
  if (progressBar) {
    progressBar.remove();
  }
};
