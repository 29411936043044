import confetti from "canvas-confetti";

// Show confetti animation and play sound when event is rated 3 or 4
function confettiAndSound() {
  const bigEmoji = document.querySelector(".big-emoji-show");
  if (!bigEmoji) return;

  const typeEmoji = bigEmoji.src;
  if (typeEmoji.includes("delighted") || typeEmoji.includes("happy")) {
    throwConfetti();
    playRandomSound();
    document.addEventListener("click", () => {
      confetti.reset();
      mySound.pause();
    });
  }
}

const throwConfetti = () => {
  confetti({
    particleCount: 100,
    spread: 150,
    origin: { y: 0.8 },
  });
};

let mySound;

const playRandomSound = () => {
  const myPlaylist = [
    "../../assets/yay.wav",
    "../../assets/crowd-cheer.mp3",
    "../../assets/firework-show.mp3",
    "../../assets/crowd-cheer.mp3",
    "../../assets/wedding-applause-cheerfully.mp3",
    "../../assets/yay2.mp3",
  ];
  const randomPlaylistEl =
    myPlaylist[Math.floor(Math.random() * myPlaylist.length)];

  mySound = new Audio(randomPlaylistEl);

  mySound.volume = 0.2;
  mySound.play();
};

export { confettiAndSound };
