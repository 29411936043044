function calendarSwitch() {
  document.querySelectorAll("[type=radio][name=calendar]").forEach((radio) => {
    radio.addEventListener("change", (event) => {
      Turbo.clearCache(); // Prevent cache flash when switching calendar
      Turbo.visit(`/users/${event.currentTarget.value}`);
    });
  });
}

export {calendarSwitch};
