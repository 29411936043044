function highlightBorder() {
  const highlightElements = document.querySelectorAll(".highlight");
  if (!highlightElements) return;
  highlightElements.forEach((el) =>
    el.addEventListener("click", (e) => {
      e.stopPropagation();
      el.classList.add("element-clicked");
      setTimeout(() => {
        el.classList.remove("element-clicked");
      }, 2000);
    })
  );
}

export { highlightBorder };
