import { swalWithAVCButtons } from "./sweetalert";

const initSweetDelete = (selector) => {
  const swalButtons = document.querySelectorAll(selector);
  swalButtons.forEach((swalButton) => {
    swalButton.addEventListener("click", (event) => {
      // Selected item id, start date, recurring (boolean) data from view
      const itemId = event.currentTarget.dataset.id;
      const itemStart = event.currentTarget.dataset.start;
      const recurring = JSON.parse(event.currentTarget.dataset.recurring); // Convert string to boolean
      confirmDelete(itemId, itemStart, recurring);
    });
  });

  // prevent the delete button from closing the modal
  $(document).on('click', '[prevent-modal-close]', (e) => {
    e.preventDefault();
  });
};

const confirmDelete = (itemId, itemStart, recurring) => {
  // Delete single events
  let title = I18n.t("Delete this event?");
  let confirmButtonText = I18n.t("Delete event");
  // Delete recurring events
  if (recurring) {
    title = I18n.t("Delete recurring event?");
    confirmButtonText = I18n.t("All events");
  }
  swalWithAVCButtons
    .fire({
      showDenyButton: recurring, // Deny button used to handle delete instance of recurring Items only
      showCancelButton: true,
      title: title,
      confirmButtonText: confirmButtonText,
      denyButtonText: I18n.t("This event only"),
      cancelButtonText: I18n.t("Cancel"),
      didOpen: () => {
        if (!recurring) {
          // Remove vertical styling when only two buttons (ie. not recurring item)
          document.querySelector(".swal2-actions").classList.remove("swal-vertical");
        }
      }
    })
    .then((result) => {
      if (result.isConfirmed) {
        // Success message for Delete All Recurring Items or Delete Single Item
        const successTitle =
          recurring === true ? I18n.t("Events deleted") : I18n.t("Event deleted");
        swalWithAVCButtons
          .fire({
            title: successTitle,
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          })
          .then(() => {
            document.querySelector(`#item-delete-${itemId}`).click(); // Delete Item from db
          });
      } else if (result.isDenied) {
        // Success message for Delete One Recurring Items (ItemException)
        swalWithAVCButtons
          .fire({
            title: I18n.t("Event deleted"),
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          })
          .then(() => {
            document.querySelector(`#item-delete-${itemId}-${moment(itemStart).format("YYYYMMDDTHHmm")}`).click(); // Add ItemException (hide recurrence so looks like deleted)
          });
      }
    });
};

export { initSweetDelete };
