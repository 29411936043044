class CookieBar {
  constructor() {
    this.cookiesBar = document.getElementById('cookies-bar');
  }

  init() {
    if (this.cookiesAllowed()) {
      this.appendGACode();
    }
    this.addButtonBehaviors();
  }

  cookiesAllowed() {
    return Cookies.get('allow_cookies') === 'yes';
  }

  addButtonBehaviors() {
    if (!this.cookiesBar) {
      return;
    }
    this.cookiesBar.querySelector('.accept').addEventListener('click', () => {
      this.allowCookies(true);
      this.cookiesBar.style.display = "none";
    });
    this.cookiesBar.querySelector('.reject').addEventListener('click', () => {
      this.cookiesBar.style.display = "none";
      this.allowCookies(false);
    });
  }

  appendGACode() {
    if (document.querySelectorAll('script[src*="https://www.googletagmanager.com').length < 1) { // Ensure only add script once
      const head = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-60MJNRYLFN";
      head.insertBefore(script, head.firstChild);
    }
  }

  allowCookies(allow) {
    if (allow) {
      Cookies.set('allow_cookies', 'yes', {
        expires: 365
      });
      this.appendGACode();

    } else {
      Cookies.set('allow_cookies', 'no', {
        expires: 365
      });
      console.log("Cookies not accepted");
    }
    this.cookiesBar.classList.add('hidden');
  }
}

document.addEventListener('turbo:load', () => {
  const cookieBar = new CookieBar();
  cookieBar.init();
});
