import I18n from "i18n-js";
import { swalWithAVCButtons } from "./sweetalert";

const initSweetEdit = (selector) => {
  const swalButton = document.querySelector(selector);
  if (swalButton) {
    swalButton.addEventListener("click", (event) => {
      const recurring =
        document.querySelector("#recurring_boolean").value === "true";
      if (recurring) {
        removeWaitSpinner();
        confirmEdit();
      } else {
        document.querySelector(".edit_item").requestSubmit();
      }
    });
  }
};

const confirmEdit = () => {
  // update_item is true by default for recurring events, unless user changes recurring_select value
  // refer to recurring_select.js.erb save(new_rule) function for details
  const updateItem = document.querySelector("#update_item");
  const showEventOnlyOption = updateItem ? JSON.parse(updateItem.value) : true;

  swalWithAVCButtons
    .fire({
      showDenyButton: showEventOnlyOption, // true by default
      showCancelButton: true,
      title: I18n.t("Edit recurring event?"),
      confirmButtonText: I18n.t("All events"),
      denyButtonText: I18n.t("This event only"),
      cancelButtonText: I18n.t("Cancel"),
    })
    .then((result) => {
      if (result.isConfirmed) {
        document.querySelector("#update_item").value = true;
        document.querySelector(".edit_item").requestSubmit();
        addWaitSpinner();
      } else if (result.isDenied) {
        document.querySelector("#update_item").value = false;
        document.querySelector(".edit_item").requestSubmit();
        addWaitSpinner();
      }
      const submitButton = document.querySelector("#submit-button");
      submitButton.addEventListener("click", (event) => {
        confirmEdit();
      });
    });
};

export { initSweetEdit, confirmEdit };
