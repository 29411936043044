/**
 * Shepherd helper functions
 */
import { language } from "../plugins/language.js";

// basic button
const button = (text, classes, action) => ({ text, classes, action });

// redirect to url
// const redirect = (path) => (() => { window.location.href = path; });
const redirect = (path, paramKey, paramVal) => (() => { window.location.href = `${path}?${paramKey}=${paramVal}`; });

// attach modal to element
const attachToAttr = (anchor, position) => ({
  highlightClass: 'shepherd-highlight-element',
  attachTo: {
    element: `[data-shepherd="${anchor}"]`,
    on: position,
  },
});

// remove url parameters
const clearUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('welcome');
  const paramsStr = urlParams.toString();
  const newUrl = paramsStr.length > 0 ? `${window.location.pathname}?${paramsStr}` : window.location.pathname;
  window.history.pushState({}, document.title, newUrl);
}

// unbind all event listeners
const unbindEventListeners = () => {
  $('.app-container').off('click', '.email-txt');
  $('.app-container').off('click', '[data-shepherd="new-event"]');
  $('.shepherd-content').off('click', '.shepherd-cancel-icon');
}

// cancel shepherd tour (can be attached to custom tour buttons)
const cancelTour = () => {
  $('.shepherd-cancel-icon').trigger('click');
};

// common step attributes
const sharedStepOptions = (tour) => ({
  cancelIcon: { enabled: true },
  buttons: [
    button(language === "ja" ? '戻る' : 'Back', 'shepherd-back-btn', tour.back),
    button(language === "ja" ? '次へ' : 'Next', 'shepherd-next-btn', tour.next),
  ],
  when: {
    cancel: () => {
      clearUrlParams();
      unbindEventListeners();
    }
  }
});

export {
  attachToAttr,
  button,
  cancelTour,
  clearUrlParams,
  redirect,
  sharedStepOptions,
  unbindEventListeners,
};
