/**
 * This is the main Shepherd util
 *
 *
 * Shepherd uses a URL parameter to facilitate moving between actions.
 *
 * Please use the Shepherd data-attribute "data-shepherd" on HTML elements when possible.
 * This is to make it clear that an element is attached to a Shepherd tour.
 *
 * Shepherd content is in shepherd-content.js. TODO: move to I18n
 */
import Shepherd from 'shepherd.js';
import '/node_modules/shepherd.js/dist/css/shepherd.css';
// import I18n from "i18n-js"; // not working with shepherd?
import { language } from '../plugins/language.js';
// I18n not working with shepherd, so using this content file instead
import shepherdContent from './shepherd-content.js';
import {
  attachToAttr,
  button,
  cancelTour,
  redirect,
  sharedStepOptions,
} from './shepherd-helpers.js';

const URL_PARAM_KEY = 'welcome';
const ACTION_DELAY = 1000;

// set defaults shepherd options applied to all tour steps
const defaultOptions = {
  useModalOverlay: true,
  exitOnEsc: true,
  scrollTo: true,
  cancelIcon: {
    enabled: true,
  },
}

// choose content based on user language and browser settings
const locale = language || window.navigator.language || 'en';
const content = (locale === 'ja') ? shepherdContent.ja : shepherdContent.en;

/**
 * Instantiate tours
 *   different tours need to be instantiated when there are unique triggers to start the tour
 *   i.e. if the shepherd tour is waiting for a button click, a new tour needs to be created
 *   and triggered by an event listener. See the event listeners at the bottom of this file.
 */
const tour01 = new Shepherd.Tour({ defaultOptions }); // simple view
const tour02 = new Shepherd.Tour({ defaultOptions }); // simple view
const tour03 = new Shepherd.Tour({ defaultOptions }); // simple view
const tour04 = new Shepherd.Tour({ defaultOptions }); // full calendar
const tour05 = new Shepherd.Tour({ defaultOptions }); // simple view

const urlParams = {
  tour01: 'newjoiner',
  tour02: 'newjoiner',
  tour03: 'neweventcreated',
  tour04: 'fullcal',
  tour05: 'simpleview',
};

// (simple view) welcome screen; triggered by new account creation (param: newjoiner)
tour01.addStep({
  ...sharedStepOptions(tour01),
  classes: 'simple-view-tour-step1',
  text: content.tour01.step01,
  buttons: [
    button(content.letsGoBtn, 'shepherd-next-btn', tour01.next),
    button(content.noThanksBtn, 'btn-tertiary step1-no-help-needed-btn', cancelTour),
  ],
});

// (simple view) this is the parent's portal
tour01.addStep({
  ...sharedStepOptions(tour01),
  text: content.tour01.step02
});

// (simple view) weekly schedule
tour01.addStep({
  ...sharedStepOptions(tour01),
  text: content.tour01.step03,
  ...attachToAttr('events-wrapper', 'bottom'),
});

// (simple view) create new event
tour01.addStep({
  ...sharedStepOptions(tour01),
  text: content.tour01.step04,
  buttons: [
    button(content.backBtn, 'shepherd-back-btn', tour01.back),
    button(content.nextBtn, 'shepherd-next-btn', tour01.cancel),
  ],
  ...attachToAttr('new-event', 'top'), // position above the create new event button
  // add persisting button trigger highlight; removed on click
  when: {
    show: () => {
      $('#newevent').addClass('custom-shepherd-target');
    }
  }
});

// (simple view) provides audio; triggered by create new event button click
tour02.addStep({
  ...sharedStepOptions(tour02),
  text: content.tour02.step01,
  buttons: [
    button(content.nextBtn, 'shepherd-next-btn', tour02.next),
  ],
  ...attachToAttr('hear-text', 'bottom'), // position below the hear text area
});

// (simple view) upload photos
tour02.addStep({
  ...sharedStepOptions(tour02),
  text: content.tour02.step02,
  ...attachToAttr('upload-photo', 'top'),
});

// (simple view) click save
tour02.addStep({
  ...sharedStepOptions(tour02),
  text: content.tour02.step03,
  ...attachToAttr("save-button", "top"),

  // add persisting button trigger highlight
  when: {
    show: () => {
      $("#submit-button").addClass("custom-shepherd-target");
    },
  },
});

// (simple view) new event created; triggered by new event created (param: neweventcreated)
tour03.addStep({
  ...sharedStepOptions(tour03),
  text: content.tour03.step01,
  buttons: [
    button(content.nextBtn, 'shepherd-next-btn', tour03.next),
  ],
  ...attachToAttr('edit-event-buttons', 'top'), // position above the edit/delete event buttons
});

// (simple view) switch to fullcal
tour03.addStep({
  ...sharedStepOptions(tour03),
  text: content.tour03.step02,
  buttons: [
    button(content.backBtn, 'shepherd-back-btn', tour03.back),
    button(content.nextBtn, 'shepherd-next-btn', redirect('/users/fullcal', URL_PARAM_KEY, urlParams.tour04)),
  ],
  ...attachToAttr('switch-to-full-cal', 'bottom'), // position below the full calendar button
});

// (fullcal) this is the calendar page; triggered by redirecting to fullcal pg (param: fullcal)
tour04.addStep({
  ...sharedStepOptions(tour04),
  text: content.tour04.step01,
  buttons: [
    button(content.backBtn, 'shepherd-back-btn', redirect('/users/show', URL_PARAM_KEY, urlParams.tour03)),
    button(content.nextBtn, 'shepherd-next-btn', tour04.next),
  ],
  when: {
    hide: () => {
      // scroll the calendar left if the recently created event is not visible
      // some days of the week are not visible under 668px screen width
      if ($('body').width() < 668) {
        const eventCell = $('.fc-daygrid-event').closest('.fc-daygrid-day');
        const eventCellIndex = $('.fc-daygrid-event').closest('tr').children().index(eventCell);
        // if the recently created event is after Tuesday (index=2)
        if (eventCellIndex > 2) {
          const calendarView = $('.fc-dayGridMonth-view');
          const eventCellWidth = eventCell.width();
          const scrollByAmount = (eventCellIndex - 2) * eventCellWidth;
          calendarView.scrollLeft(scrollByAmount);
        }
      }
    }
  }
});

// (fullcal) view or edit events
tour04.addStep({
  ...sharedStepOptions(tour04),
  text: content.tour04.step02,
  buttons: [
    button(content.backBtn, 'shepherd-back-btn', tour04.back),
    button(content.nextBtn, 'shepherd-next-btn', tour04.next),
  ],
  attachTo: { element: '.fc-daygrid-event', on: 'bottom' }, // position next to the event
});

// (fullcal) add new event
tour04.addStep({
  ...sharedStepOptions(tour04),
  text: content.tour04.step03,
  buttons: [
    button(content.backBtn, 'shepherd-back-btn', tour04.back),
    button(content.nextBtn, 'shepherd-next-btn', tour04.next),
  ],
  attachTo: { element: '.fc-daygrid-day:nth-child(4) .fc-daygrid-day-number', on: 'bottom' }, // position next to a date
});

// (fullcal) switch to weekly schedule
tour04.addStep({
  ...sharedStepOptions(tour04),
  text: content.tour04.step04,
  buttons: [
    button(content.backBtn, 'shepherd-back-btn', tour04.back),
    button(content.nextBtn, 'shepherd-next-btn', redirect('/users/show', URL_PARAM_KEY, urlParams.tour05)),
  ],
  ...attachToAttr('switch-to-simple-view', 'bottom'), // position below the simple view button
});

// (simple view) ...
tour05.addStep({
  ...sharedStepOptions(tour05),
  text: content.tour05.step01,
  buttons: [
    button(content.backBtn, 'shepherd-back-btn', redirect('/users/fullcal', URL_PARAM_KEY, urlParams.tour04)),
    button(content.nextBtn, 'shepherd-next-btn', tour05.next),
  ],
  ...attachToAttr('calendar-btn', 'bottom'),
});

// (simple view) ...
tour05.addStep({
  ...sharedStepOptions(tour05),
  text: content.tour05.step02,
  buttons: [
    button(content.backBtn, 'shepherd-back-btn', tour05.back),
    button(content.nextBtn, 'shepherd-next-btn', tour05.next),
  ],
  ...attachToAttr('home-btn', 'bottom'),
});

// (simple view) done!
tour05.addStep({
  ...sharedStepOptions(tour05),
  text: content.tour05.step03,
  buttons: [
    button(content.backBtn, 'shepherd-back-btn', tour05.back),
    button(content.finishBtn, 'shepherd-next-btn', cancelTour),
  ],
});


/**
 * Start Shepherd
 */
const startShepherd = () => {
  const param = new URLSearchParams(window.location.search).get(URL_PARAM_KEY);

  // only load for parent's portal
  if ($('.ns-simple-view').length > 0) {
    if (param === urlParams.tour01) {
      // trigger tour 1; initial steps
      tour01.start();
    }

    // note: tour02 is triggered by a click event below

    if (param === urlParams.tour03) {
      // trigger tour 3; new event created
      // delay feels more natural here; remove is necessary
      setTimeout(() => tour03.start(), ACTION_DELAY);
    }

    if (param === urlParams.tour05) {
      // trigger tour 5; final steps
      tour05.start();
    }
  }

  // only load for full calendar
  if ($('.fullcal-container').length > 0) {
    // trigger tour 1; only load for full-cal
    if (param === urlParams.tour04) {
      tour04.start();
    }
  }

  // trigger tour 2; new event tour
  $('.app-container').on('click', '[data-shepherd="new-event"]', () => {
    const param = new URLSearchParams(window.location.search).get(URL_PARAM_KEY);
    if (param === urlParams.tour02) {
      // remove highlight on button trigger
      $('#newevent').removeClass('custom-shepherd-target');
      // need delay to allow the modal time to load in the DOM
      setTimeout(() => tour02.start(), ACTION_DELAY);
    }
  });
};

export { startShepherd };
