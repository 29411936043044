import { language } from "./language";
import { voice } from "./voice";

let lastClick = 0;
const delay = 2000;
let prevItemId = 0;

function voiceContent() {
  // Speak days of the week on click
  const days = document.querySelectorAll(".simple-calendar-day");
  days.forEach((day) => {
    const itemId = day.dataset.itemid;
    day.addEventListener("click", function () {
      if (lastClick >= Date.now() - delay && prevItemId === itemId) return;
      voice.say(day.innerHTML);
      prevItemId = itemId;
      lastClick = Date.now();
    });
  });

  // Speak new & edit event form text preview
  const preview = document.querySelector(".voice-preview");
  if (preview) {
    if (lastClick >= Date.now() - delay) return;
    preview.addEventListener("click", function (event) {
      event.stopImmediatePropagation(); // stop repeat (multiple events fired) when form replaced by turbo
      voice.say(preview.innerText);
      lastClick = Date.now();
    });
  }

  // Speak today banner of dashboard
  const banner = document.querySelector(".today-banner");
  if (banner) {
    if (lastClick >= Date.now() - delay) return;
    banner.addEventListener("click", function () {
      voice.say(banner.innerText);
      lastClick = Date.now();
    });
  }

  // Speak date on dashboard
  const currentDate = document.getElementById("current-date");
  const dateString = document.querySelector(".datestring");
  if (currentDate) {
    currentDate.addEventListener("click", function () {
      let spokenDate;
      switch (language) {
        case "en":
          spokenDate = `Today is ${dateString.innerText}`;
          break;
        case "ja":
          spokenDate = `きょうは${dateString.innerText}です`;
          break;
        default:
          spokenDate = `Today is ${dateString.innerText}`;
      }
      if (lastClick >= Date.now() - delay) return;
      voice.say(spokenDate);
      lastClick = Date.now();
    });
  }

  // Speak happy event on clicking rating 1 as consolation
  const sad = document.querySelector("#readsad");
  if (sad) {
    switch (language) {
      case "en":
        const comfort =
          "Thank you for telling me how you feel. Just remember you get to";
        voice.say(comfort + " " + sad.dataset.happyDetails);
        break;
      case "ja":
        const comfortStart =
          "教えてくれてありがとう！その気持ちわかるよ。でも大丈夫！ もうすぐ";
        const comfortEnd = "！";
        voice.say(comfortStart + sad.dataset.happyDetails + comfortEnd);
        break;
      default:
    }
  }
}

export { voiceContent };
