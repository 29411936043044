// Global site tag (gtag.js) - Google Analytics
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments);}

  gtag("js", new Date());

  document.addEventListener("turbo:load", function(event){
    gtag("config", "G-60MJNRYLFN", {
      page_location: event.detail.url,
      // page_path: event.path[2] ? event.path[2].location.pathname : null,
      // page_title: event.path[2] ? event.path[2].document.title : null,
      cookie_flags: "max-age=7200;secure;samesite=none",
    });
  });

export {gtag};
