const stickyNavbar = () => {
  // hide the sticky navbar only on the landing page
  if (document.querySelector('.landingpage-body')) {
    const navBar = document.getElementById('navbar');
    if (window.scrollY >= 80) {
      navBar.classList.add('show');
    } else {
      navBar.classList.remove('show');
    }
  }
  window.addEventListener('scroll', stickyNavbar );
}

export { stickyNavbar };
