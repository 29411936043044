import{ env } from '../plugins/environments.js';

export default {
  en: {
    letsGoBtn: "Let’s go",
    noThanksBtn: "No thanks, I’ll look around myself",
    backBtn: "Back",
    nextBtn: "Next",
    finishBtn: "Finish tour",
    // simple view tour
    tour01: {
      step01: `
        <div class="step1-icon"></div>
        <h4 class="step1-title"><span class="no-wrap">Welcome to</span> <span class="no-wrap">AVC Calendar!</span></h4>
        <p>We’re so glad to have you here, and<br/>thanks for joining our community!</p>
        <p>To get started with a brief tour, click<br/>the button below.</p>
      `,
      step02: `
        This screen is the <b>Parent’s Portal</b><br/>
        where you can create and manage<br/>
        the schedule for your child.
      `,
      step03: `
        This area is the weekly schedule.<br/>
        Events that you create will appear here.
      `,
      step04: `
        To get started, click Next and</br>
        then click <b>Add a new event</b>
        <br/>to create your first event.
      `,
    },
    tour02: {
      step01: `
        First, add a title and details for your child's event.<br/>
        <br/>
        Text in <b>Details</b> will be spoken on your child's screen.<br/>
        <p>Click <i class="fas fa-volume-up"></i> to confirm what will be spoken.</p>
      `,
      step02: `
        Let's include a photo for your event.<br/>
        <br/>
        You can use the search function, <br/>
        or upload a photo of your own.<br/>
      `,
      step03: `
        When you're happy with your event,<br/>
        click the <b>Save</b> button
      `,
    },
    tour03: {
      step01: `
        <em>Congratulations,</em><br/>
        <em>You’ve created your first event!</em><br/>
        <br/>
        You can use these buttons to<br/>
        edit or delete as needed.
      `,
      step02: `
        You can click here for the <b>Calendar View</b>.<br/>
        Here you can view all your events by month, week or day.
      `,
    },
    // full calendar tour
    tour04: {
      step01: `
        This is the monthly calendar page.<br/>
        Here you can view all your events at a glance.
      `,
      step02: `
        View or edit events by clicking<br/>
        on them here.
      `,
      step03: `
        Click on any specific date to add a<br/>
        new event on that date.
      `,
      step04: `
        Click here to go back to the<br/>
        weekly schedule.
      `,
    },
    // back to simple view tour
    tour05: {
      step01: `
        This is the <b>Week View</b> for your child.<br/>
        Here they can check all events for the week, and express how much they like each event with the following icons:<br/>
          <img class="card-emoji-small" src="https://res.cloudinary.com/${env}/image/upload/c_scale,w_23/assets/shepherd/emotion=sad.png">
          <img class="card-emoji-small" src="https://res.cloudinary.com/${env}/image/upload/c_scale,w_24/assets/shepherd/emotion=neutral.png">
          <img class="card-emoji-small" src="https://res.cloudinary.com/${env}/image/upload/c_scale,w_24/assets/shepherd/emotion=happy.png">
          <img class="card-emoji-small" src="https://res.cloudinary.com/${env}/image/upload/c_scale,w_23/assets/shepherd/emotion=delighted.png"><br/>
      `,
      step02: `
        This is the <b>Dashboard</b>, where your child can check events for today and also check today's date and weather.
      `,
      step03: `
        The Week View and Dashboard are fully voiced.<br/>
        Just touch or click any item to hear it speak.<br/>
        <br/>
        And that’s all there is to it.<br/>
        We hope you enjoy using our app!
      `,
    },
  },
  ja: {
    letsGoBtn: "使い方のご案内",
    noThanksBtn: "結構です。自分で使います。",
    backBtn: "戻る",
    nextBtn: "次へ",
    finishBtn: "完了",
    // simple view tour
    tour01: {
      step01: `
        <div class="step1-icon"></div>
        <h4 class="step1-title"><span class="no-wrap">AVCカレンダー</span><span class="no-wrap">へようこそ!</span></h4>
        <p>ご登録していただきありがとうございます。</p>
        <p>使い方についてご案内いたしますので、ぜひ<br/>以下の「使い方のご案内」をクリックしてください。</p>
      `,
      step02: `
        こちらのページは<b>「おうちの方用」</b>の画面です。<br/>
        <br/>
        こちらでお子様の予定作成などの<br/>
        スケジュール管理ができます。
      `,
      // add box to surround week in red
      step03: `
        こちらはお子様の１週間の予定です。<br/>
        作成した予定はこちらに表示されます。
      `,
      step04: `
        始めに、ここをクリックし<br/>
        最初の予定を作成してみましょう。
      `,
    },
    tour02: {
      // replace * with bell icon
      step01: `
        まずは、<b>タイトル</b>と<b>詳細</b>を入力してみましょう。<br/>
        <br/>
        お子様が使用する画面では、<b>詳細</b>が音声で再生されます。<br/>
        <p>「<i class="fas fa-volume-up"></i>」をクリックすると音声を確認していただけます。</p>
      `,
      // Highlight upload photo in red
      step02: `
        次に、写真をアップしましょう。<br/>
        <br/>
        写真は、こちらの検索機能を使い選択するか、<br/>
        お持ちの写真もアップロードしてご使用できます。<br/>
        <br/>
        どうぞ、写真の選択をしてください。
      `,
      step03: `
        予定の入力が終わりましたら、<br/>
        最後に<b>保存</b>をクリックしてください。
      `,
    },
    tour03: {
      // red box around edit and delete buttons
      // Add a new box above save Button
      // 最後に、予定の作成が終わりましたらこちらをクリックしてください。
      step01: `
        最初の予定が作成できました！<br/>
        <br/>
        こちらで予定の編集と削除も可能です。
      `,
      step02: `
        こちらでは、月・週・日ごとの<br/>
        スケジュール表示ができます。
      `,
    },
    // full calendar tour
    tour04: {
      step01: `
        こちらが月ごとの画面です。<br/>
        ここで全ての予定が確認できます。
      `,
      step02: `
        ここをクリックすると<br/>
        予定の編集ができます。
      `,
      step03: `
        新規予定を作成するには<br/>
        日付をクリックしてください。
      `,
      step04: `
        こちらをクリックすると<br/>
        シンプル表示の画面に戻ります。
      `,
    },
    // back to simple view tour
    tour05: {
      step01: `
       <b>お子様用の１週間の予定です。</b><br/><br/>
       こちらでお子様は１週間の予定を確認しながら<br/>
       ４段階のマーク（
          <img class="card-emoji-small" src="https://res.cloudinary.com/${env}/image/upload/c_scale,w_23/assets/shepherd/emotion=sad.png">
          <img class="card-emoji-small" src="https://res.cloudinary.com/${env}/image/upload/c_scale,w_24/assets/shepherd/emotion=neutral.png">
          <img class="card-emoji-small" src="https://res.cloudinary.com/${env}/image/upload/c_scale,w_24/assets/shepherd/emotion=happy.png">
          <img class="card-emoji-small" src="https://res.cloudinary.com/${env}/image/upload/c_scale,w_23/assets/shepherd/emotion=delighted.png"> ）<br/>
        で予定を評価できます。`,
      step02: `
       こちらはお子様が簡単に<br/>
       今日の予定・日付・天気を確認できる場所です。
       `,
      step03: `
        ご案内は以上です。<br/>
        <br/>
        AVCカレンダーが、どうかお役に立ちますように！
      `,
    },
  },
};
