import { language } from "./language";
import { voice } from "./voice";

function weather() {
  const currentWeather = document.getElementById("current-weather");
  if (currentWeather) {
    openWeather(); // Display weather on first page load
    currentWeather.addEventListener(
      "click",
      (event) => {
        openWeather(event);
      },
      false
    );
  }
  let lastClick = 0;
  // Display weather and speak on click
  function openWeather(event) {
    const weather = document.querySelector("#weather-api");
    const weatherBox = document.getElementById("current-weather");
    if (weather && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // Display weather box only when position is available
        weatherBox.style.display = "flex";
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        fetch(
          `https://api.openweathermap.org/data/2.5/weather?lang=${language}&lat=${lat}&lon=${lon}&appid=${process.env.OPEN_WEATHER_KEY}`
        )
          .then((response) => response.json())
          .then((data) => {
            weather.style.backgroundImage = `url(https://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png)`;
            let spokenWeather;
            switch (language) {
              case "en":
                spokenWeather = `Today we are having ${data.weather[0].description}`;
                break;
              case "ja":
                spokenWeather = `${data.weather[0].description}です`;
                break;
              default:
                spokenWeather = data.weather[0].description;
            }

            if (event) {
              const delay = 2000;
              if (event.type == "click" && lastClick >= Date.now() - delay) {
                return;
              } else {
                lastClick = Date.now();
                voice.say(spokenWeather);
              }
            }
          });
      });
    }
  }
}

export { weather };
