const languageToggle = () => {
  // toggle global JS "locale" variable between Japanese/Englsh when language toggle button clicked
  const buttons = document.querySelectorAll('.language-toggle');
  buttons.forEach(element => {
    element.addEventListener('click', () => {
      locale = (locale =='en'? 'ja' : 'en');
    });
  });
 };

export { languageToggle };
