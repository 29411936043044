// Display preview of attached photo on page load (for edit form only)
function photoPreview() {
  const preview = document.querySelector('#preview-edit');
  if (preview && preview.dataset.existingphoto != "none") {
    document.getElementById('display-image').src = preview.dataset.existingphoto;
  } else if (preview && preview.dataset.existingunsplash != "none") {
    document.getElementById("display-image").src = preview.dataset.existingunsplash;
  }
};

export {photoPreview};
