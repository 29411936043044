$(document).on('turbo:load', function() {
  // Select all elements with email class on page
  const email = document.querySelectorAll('.email-embed')
  if (email) {
    // for each element, if clicked set href to launch email client
    email.forEach(element => {
      element.addEventListener('click', () => {
        // Decodes email address and subject from base 64. https://www.base64encode.org/
        element.href = `mailto:${atob(element.dataset.address)}?subject=${atob(element.dataset.subject)}`;
      });
    });
  }
});
