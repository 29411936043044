import { Controller } from 'stimulus';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import jaLocale from '@fullcalendar/core/locales/ja';
import Rails from '@rails/ujs';
import { settings, bootstrap4Fix, updateEndDate } from '../plugins/flatpickr';

let fpNewStartDate = new Date();
let fpNewEndDate = new Date();

// Tablet and PC layout
let left = 'prev,next today';
let center = 'title';
let right = 'dayGridMonth,timeGridWeek,timeGridDay';
if (window.outerWidth < 768) {
  left = 'prev,next';
  center = 'dayGridMonth,timeGridWeek,timeGridDay';
  right = 'title';
}

export default class extends Controller {
  static targets = ['calendar', 'modal', 'start_date', 'end_date'];

  connect() {
    let _this = this
    let calendar = new Calendar(this.calendarTarget, {
      events: 'fullcal.json',
      locales: [jaLocale],
      locale: document.getElementById('voice').dataset.language,
      editable: true,
      navLinks: true,
      themeSystem: 'bootstrap',
      eventLongPressDelay: 0,
      selectLongPressDelay: 1000,
      eventTimeFormat: {
        // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: true,
      },
      headerToolbar: {
        left: left,
        center: center,
        right: right,
      },
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      navLinkDayClick: function (date, jsEvent) {
        $('#NewEventModal').modal('show');
        const utcOffsetHours = -(new Date().getTimezoneOffset() / 60);
        const localDate = new Date(
          date.setHours(date.getHours() + utcOffsetHours)
        );
        // initialize start date flatpickr for new event form (launched from fullcal)
        fpNewStartDate = flatpickr('#fullcal_new_start_date', {
          ...settings,
          defaultDate: localDate.toISOString().replace('T', ' ').replace(/:00.000Z/, ''),
          onOpen: (selectedDates, dateStr, instance) => bootstrap4Fix(),
          onClose: (selectedDates, dateStr, instance) => bootstrap4Fix(),
          onChange: (selectedDates, dateStr, instance) => updateEndDate(fpNewStartDate, fpNewEndDate),
        });
        // initialize end date flatpickr for new event form (launched from fullcal)
        fpNewEndDate = flatpickr('#fullcal_new_end_date', {
          ...settings,
          defaultDate: localDate.toISOString().replace('T', ' ').replace(/:00.000Z/, ''),
          onOpen: (selectedDates, dateStr, instance) => bootstrap4Fix(),
          onClose: (selectedDates, dateStr, instance) => bootstrap4Fix(),
        });
      },
      eventClick: function (info) {
        info.jsEvent.preventDefault();

        // Params necessary to set start and end date correctly in edit modal
        const params = new URLSearchParams(window.location.search);
        params.set('start_time', moment(info.event.start).format());
        const endTime = info.event.end || info.event.start; // if end is set null in fullcal (start_date and end_date same), then set to start_date
        params.set('end_time', moment(endTime).format());

        let modal = document.querySelector("#modal");
        modal.src = `${info.event.extendedProps.edit_url}?${params.toString()}`;
      },
      eventDrop: function (info) {
        let data = _this.data(info);
        const params = new URLSearchParams(data);
        params.set('fullcal_start', moment(info.oldEvent.start));
        Rails.ajax({
          type: 'PUT',
          url: info.event.url,
          data: params.toString(),
        });
      },
      eventResize: function (info) {
        let data = _this.data(info);
        Rails.ajax({
          type: 'PUT',
          url: info.event.url,
          data: new URLSearchParams(data).toString(),
        });
      },
    });
    calendar.render()

    let totalHeight = document.querySelector('#navbar').offsetHeight;
    totalHeight += document.querySelector('.fullcal-switch').offsetHeight;
    totalHeight += document.querySelector('.justify-content-center').offsetHeight;
    totalHeight += document.querySelector('.fc-toolbar').offsetHeight * 2;
    const calHeight = `calc(100vh - ${totalHeight}px)`;
    calendar.setOption('contentHeight', calHeight);

    // Code to ensure button and title styled nicely in mobile view
    window.addEventListener('resize', adjustCalHeight, false);
    window.addEventListener('orientationchange', adjustCalHeight, false);
    document.addEventListener('turbo:load', adjustCalHeight, false);

    function adjustCalHeight() {
      if (document.querySelector('.fc-toolbar')) {
        // Move title to next row for small devices
        if (window.outerWidth <= 576) {
          document
            .querySelector('.fc-toolbar.fc-header-toolbar')
            .classList.add('row', 'col-lg-12');
        } else {
          document
            .querySelector('.fc-toolbar.fc-header-toolbar')
            .classList.remove('row', 'col-lg-12');
        }

        setTimeout(function () {
          if (window.outerHeight <= 576) {
            calendar.setOption('contentHeight', '70vh');
          } else {
            calendar.setOption('contentHeight', calHeight);
          }
        }, 150);
      }
    }
  }

  data(info) {
    return {
      'item[start_date]': info.event.start,
      'item[end_date]': info.event.end
    }
  }
}
