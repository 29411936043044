// Bootstrap 4 modal on modal fix
const updateModalFocus = () => {
  const modal = document.querySelector(".modal");
  if (modal) {
    if (modal.hasAttribute("tabindex")) {
      // On modal open
      modal.removeAttribute("tabindex"); // make element focusable in sequential keyboard navigation
    } else {
      // On modal close
      modal.setAttribute("tabindex", -1); // make element not reachable via sequential keyboard navigation
      // ensure gray modal background disabled when recurring select modal is closed
      const recurringSelectBG = document.querySelector(".modal-backdrop");
      if (recurringSelectBG) {
        document.querySelector(".modal-backdrop").remove();
      }
    }
  }
}

// Temporary fix for registration page Recaptcha not working well with Turbo
// Possibly issue with devise - currently not yet compatible with Turbo (2022/01/18)
const registrationFix = () => {
  const registrationButtons = document.querySelectorAll(".new-user-registration");
  if (registrationButtons.length > 0) {
    registrationButtons.forEach((button) => {
      button.addEventListener("click", () => {
        window.location.href = `/users/sign_up?locale=${locale === 'en' ? 'en' : 'ja'}`;
      });
    });
  }
};

// Temporary fix for sign in page not submitting after first page load
const signInFix = () => {
  const loginForm = document.querySelector(".new_user");
  const loginButtons = document.querySelectorAll(".log-in-button");
  const passwordInput = document.querySelector(".password");

  if (loginForm && loginButtons.length > 0 && passwordInput) {
    // submit form on login button click
    loginButtons.forEach((button) => {
      button.addEventListener("click", () => {
        loginForm.submit();
      });
    });

    passwordInput.addEventListener("keypress", function(event) {
      // submit form on press Enter key in password field
      if (event.key === "Enter") {
        loginForm.submit();
      }
    });
  }
};

const inputHelpers = () => {
  if (document.querySelector(".modal-content")) {
    const title = document.getElementById("title");
    const details = document.getElementById("details");
    let previousTitle = title ? title.value : null;

    const inputPreview = () => {
      const detailsPreview = document.getElementById("detailsPreview");
      if (detailsPreview) {
        detailsPreview.innerText = details.value;
      }
    }

    // display input preview on form load
    inputPreview();

    const copyTitleToDetails = () => {
      // copies value to title to details, UNLESS extra text is added to details
      if (details.value === previousTitle) {
        details.value = title.value;
      }
      previousTitle = title.value;
      inputPreview(); // display updated input preview on user input
    }

    if (title && details) {
      title.addEventListener("input", copyTitleToDetails, false);
      details.addEventListener("input", inputPreview, false);
    }
  }
}

// autopopulate the dates for simple calendar view
const populateDates = () => {
  const dateButtons = document.getElementsByClassName('date-button');

  for (let button of dateButtons) {
    button.addEventListener("click", () => {
      const date = button.dataset.startDate
      // update date in modal
      const startDate = document.getElementById('new_start_date');
      const endDate = document.getElementById('new_end_date');
      startDate._flatpickr.setDate(date, true, 'Y/m/d');
      endDate._flatpickr.setDate(date, true, 'Y/m/d');
    });
  };
}

export { updateModalFocus, inputHelpers, registrationFix, signInFix, populateDates };
