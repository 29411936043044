import { voice } from "../plugins/voice";

let prevItemId = 0;
let lastClick = 0;
const delay = 2000;

function voiceItems() {
  const items = document.querySelectorAll(".read-out-loud");
  items.forEach((item) => {
    const readimg = item.querySelector(".card-image");
    const details = item.querySelectorAll(".details");
    readimg.addEventListener(
      "click",
      function (event) {
        const itemId = item.dataset.itemid;
        const audioSrc = document.getElementById(`item-${itemId}-audio`);
        if (lastClick >= Date.now() - delay && prevItemId === itemId) return;
        if (audioSrc) {
          audioSrc.play();
          prevItemId = itemId;
          lastClick = Date.now();
        } else if (details) {
          voice.say(details[0].innerText);
          prevItemId = itemId;
          lastClick = Date.now();
        }
      },
      false
    );
  });
}

export { voiceItems };
