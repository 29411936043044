import { Controller } from "stimulus";
import { confirmEdit } from "../plugins/sweetedit";

export default class extends Controller {
  connect() {
    // Prevent form submission so sweet edit confirmation displayed for recurring events
    const editEventForm = document.querySelector(".edit_item");
    const recurring = document.querySelector("#recurring_boolean");
    const recurringBoolean = recurring ? JSON.parse(recurring.value) : false;
    const submitButton = document.querySelector("#submit-button");
    const closeButton  = document.querySelectorAll(".fa-solid")

    if (editEventForm && recurringBoolean) {
      submitButton.addEventListener("click", (event) => {
        event.preventDefault();
        confirmEdit();
      });
    }

    // modal closes itself when a form post is successful
    document.addEventListener("turbo:submit-end", this.handleSubmit);

    // modal closes itself when x is clicked
    closeButton.forEach(button => button.addEventListener('click', this.hide))

    // Display bootstrap modal on open
    $("#EditEventModal").modal("show");
  }

  close() {
    $("#NewEventModal").modal("hide");  // can't remove modal because included in page
    $("#EditEventModal").remove();      // can remove modal because it's a turbo frame
    $(".modal-backdrop").remove();      // to remove modal background after form submit
    document.activeElement.classList.remove("modal-open"); // to ensure we can scroll

    // Prevent cache flash when updating next item
    Turbo.clearCache();

    // Refresh the page (necessary for Fullcal only)
    const location = window.location.href.split("/").pop();
    if (location === "fullcal") {
      window.location.href = "fullcal";
    }
  }

  hide() {
    // Used by cancel button instead of page refresh for faster response
    $("#NewEventModal").modal("hide");
    $("#EditEventModal").modal("hide");
    $("#editUser").modal("hide");
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close();
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      setTimeout(() => this.close(), 500);
    }
  }
}
