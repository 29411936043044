import flatpickr from "flatpickr";
import { language } from "../plugins/language";
import { english } from "flatpickr/dist/l10n/default";
import { Japanese } from "flatpickr/dist/l10n/ja.js";

const minuteIncrement = 60 // Step unit for minute input (incl. scrolling)
const settings = {
  allowInput: true, // Allows the user to enter a date directly into the input field.
  enableTime: true, // Enables time picker
  altInput: false, // Show the user a readable date (as per altFormat)
  minuteIncrement
}

let fpNewStartDate = new Date();
let fpNewEndDate = new Date();
let fpEditStartDate = new Date();
let fpEditEndDate = new Date();

const initFlatpickr = () => {
  switch (language) {
    case "en":
      flatpickr.localize(english);
      flatpickr.l10ns.default.firstDayOfWeek = 1;
      break;
    case "ja":
      flatpickr.localize(Japanese);
      flatpickr.l10ns.default.firstDayOfWeek = 0;
      break;
    default:
      flatpickr.localize(english);
      flatpickr.l10ns.default.firstDayOfWeek = 1;
  }

  // initialize start date flatpickr for new event form (launched from show)
  fpNewStartDate = flatpickr("#new_start_date", {
    ...settings,
    defaultDate: getRoundedUpDate(minuteIncrement),
    onOpen: (selectedDates, dateStr, instance) => bootstrap4Fix(),
    onClose: (selectedDates, dateStr, instance) => bootstrap4Fix(),
    onChange: (selectedDates, dateStr, instance) => updateEndDate(fpNewStartDate, fpNewEndDate),
  });

  // initialize end date flatpickr for new event form (launched from show)
  fpNewEndDate = flatpickr("#new_end_date", {
    ...settings,
    defaultDate: initEndDate(),
    onOpen: (selectedDates, dateStr, instance) => bootstrap4Fix(),
    onClose: (selectedDates, dateStr, instance) => bootstrap4Fix(),
  });

  // initialize start date flatpickr for edit event form (launched from show)
  fpEditStartDate = flatpickr("#edit_start_date", {
    ...settings,
    onOpen: (selectedDates, dateStr, instance) => bootstrap4Fix(),
    onClose: (selectedDates, dateStr, instance) => bootstrap4Fix(),
    onChange: (selectedDates, dateStr, instance) => updateEndDate(fpEditStartDate, fpEditEndDate),
  });

  // initialize end date flatpickr for edit event form (launched from show)
  fpEditEndDate = flatpickr("#edit_end_date", {
    ...settings,
    onOpen: (selectedDates, dateStr, instance) => bootstrap4Fix(),
    onClose: (selectedDates, dateStr, instance) => bootstrap4Fix(),
    onChange: (selectedDates, dateStr, instance) => updateEndDate(),
  });
};;

// Resolves 'Uncaught RangeError: Maximum call stack size exceeded' using bootstrap v.4 modal #1730'
const bootstrap4Fix = () => {
  const modal = document.querySelector(".modal")
  if (modal) {
    if (modal.hasAttribute("tabindex")) {
      modal.removeAttribute("tabindex"); // On flatpickr open
    } else {
      modal.setAttribute("tabindex", -1); // On flatpickr close
    }
  }
}

const initEndDate = () => {
    const endDate = getRoundedUpDate(minuteIncrement);
    return endDate.setHours(endDate.getHours() + 1);
}

const getRoundedUpDate = (minutes, d = new Date()) => {
    const ms = 1000 * 60 * minutes;
    return new Date(Math.ceil(d.getTime() / ms) * ms);
};

// startDate and endDate are instances of flatpickr
const updateEndDate = (startDate, endDate) => {
  if (startDate) {
    const newStartDate = new Date(startDate.selectedDates.toString());
    newStartDate.setHours(newStartDate.getHours() + 1);
    endDate.setDate(newStartDate);
  }
}

export { settings, initFlatpickr, bootstrap4Fix, updateEndDate };
